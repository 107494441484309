import React from "react"
import styled from "styled-components"

import Heading from "./heading"

import media from "../../styles/media"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  display: grid;
  grid-template: auto auto / 1fr;
  ${media.tablet``}
`

const HeadingContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const Body = styled.div`
  padding: 15vh 15vw 15vh 10vw;
  h4 {
    font-size: 1.25rem;
    margin: 2rem 0;
    line-height: 1.25;
    font-family: "butlermedium";
  }
  p {
    font-size: 1rem;
  }
  ul {
    li {
      list-style: none;
      font-size: 1rem;
      margin-bottom: 1rem;
      strong {
        font-family: "butlerbold";
      }
    }
  }
  ${media.tablet`
    padding: 15vh 25vw 15vh 20vw;
    h4 {
      font-size: 1.85rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.75;
    }
    ul {
      li {
        font-size: 1.25rem;
      }
    }
  `}
`

const TermsPage = () => {
  return (
    <Container>
      <HeadingContainer>
        <Heading />
      </HeadingContainer>
      <Body>
        <p>
          This website is operated by BrainFarm (PTY)LTD (Registration No
          2014/104134/07) trading as www.soundideasessions.com (hereinafter
          referred to as &#34;BrainFarm&#34;) BrainFarm’s physical address and
          contact details appear below:
        </p>

        <ul>
          <li>
            <strong>Address: </strong> Postnet Suite 1125, Private Bag X153,
            Bryanston, 2021, South Africa
          </li>
          <li>
            <strong>Tel: </strong>+27 (0)82 254 4549
          </li>
          <li>
            <strong>Fax: </strong>+27 (0)86 548 6969
          </li>
          <li>
            <strong>Email: </strong>info@brainfarm.co.za
          </li>
          <li>
            <strong>Vat No. </strong>4430274417
          </li>
        </ul>

        <p>
          KINDLY ENSURE THAT YOU READ THESE TERMS AND CONDITIONS CAREFULLY. BY
          USING THIS WEBSITE AND BY PURCHASING A TICKET TO ATTEND AN EVENT
          HOSTED BY BRAINFARM ON THIS WEBSITE YOU ARE AGREEING TO BRAINFARM’S
          TERMS AND CONDITIONS CONTAINED BELOW (AS AMENDED FROM TIME TO TIME).
        </p>

        <h4>COPYRIGHT</h4>

        <ul>
          <li>
            All rights not expressly granted are reserved. To obtain permissions
            for the commercial use of any content on this website contact the
            Account Manager at +27 (0)82 558 2189 or e-mail info@brainfarm.co.za
          </li>
          <li>
            Users may download, view and print content from this website for
            private and non-commercial purposes only.
          </li>
          <li>
            All copyrights and moral rights of BrainFarm and its employees
            and/or agents are reserved.
          </li>
          <li>
            While it uses its best endeavours to do so, BrainFarm cannot screen
            or edit all the content available from this website and does not
            accept any liability for illegal, defamatory or obscene content.
            Users are encouraged to inform BrainFarm of any content that may be
            offensive or illegal.
          </li>
        </ul>

        <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
        <ul>
          <li>
            All the content, trademarks and data on this website, including but
            not limited to, software, databases, text, graphics, icons,
            hyperlinks, private information, designs and agreements, are the
            property of or licensed to BrainFarm and as such are protected from
            infringement by local and international legislation and treaties.
          </li>
        </ul>

        <h4>ELECTRONIC COMMUNICATIONS</h4>
        <ul>
          <li>
            When you register on this website or send an e-mail to BrainFarm,
            you consent to receiving communications from BrainFarm
            electronically and you furthermore agree that all agreements,
            notices, disclosures and other communications sent by BrainFarm
            satisfy any legal requirements, including but not limited, to the
            requirement that such communications should be &#34;in writing&#34;.
          </li>
        </ul>

        <h4>DISCLAIMER</h4>
        <ul>
          <li>
            Subject to the provisions of sections 43(5) and 43(6) of the
            Electronic Communications and Transactions Act, neither BrainFarm
            nor any of its employees, agents and/or representatives shall be
            liable for any damage, loss or liability of whatsoever nature
            arising from the use or inability to use this website or the
            services or content provided from and through this website.
          </li>
          <li>
            Furthermore, BrainFarm makes no representations or warranties,
            implied or otherwise, that, amongst others, the content and
            technology available from this website are free from errors or
            omissions. Users are encouraged to report any possible malfunctions
            and errors to the persons referred to in clause 1.1.
          </li>
        </ul>

        <h4>COSTS</h4>
        <ul>
          <li>
            <strong>Prices</strong>
          </li>
          <li>
            The price of each event is displayed with details of the event. In
            the case of a discount or special offer, the discounted price or
            special offer is displayed.{" "}
          </li>
          <li>
            <strong>Transaction Currency</strong>
          </li>
          <li>
            All transactions will be processed in ZAR (South African Rands)
          </li>
          <li>
            <strong>Value Added Tax (VAT):</strong>
          </li>
          <li>
            Value Added Tax at the rate of 15% is charged on events attended by
            South African residents.
          </li>
        </ul>

        <h4>BOOKING CONFIRMATIONS</h4>
        <ul>
          <li>
            Quicket.co.za issues immediate electronic booking confirmations via
            email. If you have not received confirmation of your booking,
            contact Quicket at +27 (0)21 424 9308 or email,
            support@quicket.co.za to ensure that your booking has been
            registered.
          </li>
          <li>
            In cases of bookings not being confirmed, email
            support@quicket.co.za or call +27 (0)21 424 9308.
          </li>
        </ul>

        <h4>PAYMENT METHODS</h4>
        <ul>
          <li>
            <strong>Payments</strong>
          </li>
          <li>
            Through Quicket, BrainFarm’s credit card technology and bank
            deposits are provided by PayFast Payment Gateway.
          </li>
        </ul>

        <h4>DATES AND TIMES</h4>
        <ul>
          <li>
            <strong>Dates</strong>
          </li>
          <li>
            BrainFarm cannot be held responsible for changes to event dates
            which may be beyond its control.{" "}
          </li>
          <li>
            <strong>Times</strong>
          </li>
          <li>
            Events are run during times indicated on the registration forms or
            relevant event website, unless otherwise advertised.{" "}
          </li>
          <li>
            BrainFarm may not be held liable should any event run beyond or
            shorter than these times.
          </li>
        </ul>

        <h4>REFUND POLICY</h4>
        <ul>
          <li>
            <strong>Refunds</strong>
          </li>
          <li>
            BrainFarm shall only be obliged to refund any payment for tickets
            made by you should an event be cancelled by BrainFarm.
          </li>
          <li>
            Payment of refunds shall be effected by electronic transfer only.
          </li>
          <li>
            Payment shall be made within 30 (thirty) days of any event being
            cancelled.
          </li>
          <li>
            <strong>Substitutions</strong>
          </li>
          <li>
            Substitute delegates may be sent in your place. BrainFarm must to be
            informed, in writing, of substitutions as soon as possible.{" "}
          </li>
          <li>
            We can transfer you once to any other of our Sound Idea Sessions
            events, or event dates, provided we are notified in writing or by
            email no later than 5 working days before the event date.{" "}
          </li>
          <li>
            <strong>Non arrivals</strong>
          </li>
          <li>Non arrivals will attract the full event fee.</li>
        </ul>

        <h4>GOVERNING LAW </h4>
        <ul>
          <li>
            This website is hosted, controlled and operated from the Republic of
            South Africa and therefore governed by South African law.
          </li>
        </ul>

        <h4>SECURITY</h4>
        <ul>
          <li>
            Any person that delivers or attempts to deliver any damaging code to
            this website or attempts to gain unauthorised access to any page on
            this website shall be prosecuted and civil damages shall be claimed
            in the event that BrainFarm suffers any damage or loss.{" "}
          </li>
          <li>
            You agree and warrant that your log-in name and password shall:
          </li>
          <li>Be used for personal use only; and </li>
          <li>Not be disclosed to any third party.</li>
          <li>
            You consent to BrainFarm taking all reasonable steps to ensure the
            integrity and security of this website and back-office applications.{" "}
          </li>
          <li>
            All credit card transactions are Secure Socket Layers encrypted.{" "}
          </li>
        </ul>

        <h4>CHANGES TO AGREEMENT </h4>
        <ul>
          <li>
            BrainFarm may, in its sole discretion, change this agreement or any
            part thereof at any time without notice.{" "}
          </li>
        </ul>

        <h4>DISPUTES</h4>
        <ul>
          <li>
            In the event of any dispute of any nature whatsoever arising between
            the parties on any matter provided for in, or arising out of this
            agreement, and not resolved through the Customer Relations
            Department of BrainFarm, then such a dispute shall be submitted to
            confidential arbitration in terms of the expedited rules of the
            Arbitration Foundation of South Africa. Arbitration proceedings
            shall be conducted in Sandton in English.{" "}
          </li>
        </ul>

        <h4>DOMICILIUM</h4>
        <ul>
          <li>
            BrainFarm chooses as its domicilium citandi et executandi for all
            purposes under this agreement, whether in respect of court process,
            notice, or other documents or communication of whatsoever nature,
            BrainFarm (PTY) LTD, Postnet suite 1125, Private Bag x153,
            Bryanston, 2021.
          </li>
        </ul>

        <h4>PRIVACY</h4>
        <ul>
          <li>
            BrainFarm shall take all reasonable steps to protect your personal
            information. For the purpose of this clause, &#34;personal
            information&#34; shall be defined as detailed in the Promotion of
            Access to Information Act 2 of 2000 (PAIA).
          </li>
          <li>
            <strong>
              BrainFarm may electronically collect, store and use the following
              personal information:
            </strong>
          </li>
          <li>Name and surname;</li>
          <li>Birth date;</li>
          <li>Gender;</li>
          <li>Country of residence;</li>
          <li>Closest city; </li>
          <li>Non-personal browsing habits and click patterns; </li>
          <li>Cellphone number</li>
          <li>E-mail address; and </li>
          <li>IP address. </li>
          <li>
            <strong>
              BrainFarm collects, stores and uses the above-mentioned
              information for the following purposes:
            </strong>
          </li>
          <li>To greet you when you access this website; </li>
          <li>
            Subject to your consent, inform you of facts relating to your access
            and use of this website;{" "}
          </li>
          <li>
            Subject to your consent, to inform you about competitions and
            special offers from BrainFarm and/or its partners / affiliates;{" "}
          </li>
          <li>
            To compile non-personal statistical information about browsing
            habits, click-patterns and access to this website.{" "}
          </li>
          <li>
            Information detailed above is collected either electronically by
            using cookies or is provided voluntarily by you. You may determine
            cookie use independently through the browser settings.{" "}
          </li>
          <li>
            You may elect not to receive any communications from BrainFarm
            and/or its partners / affiliates.{" "}
          </li>
          <li>
            BrainFarm may collect, maintain, save, compile, share, disclose and
            sell any information collected from you, subject to the following
            provisions:
          </li>
          <li>
            BrainFarm shall not disclose your personal information unless you
            consent thereto;{" "}
          </li>
          <li>
            BrainFarm shall disclose information without your consent only
            through due legal process; and{" "}
          </li>
          <li>
            BrainFarm may compile, use and share any information that does not
            relate to any specific individual.
          </li>
          <li>
            BrainFarm owns and retains all rights to non-personal statistical
            information collected and compiled by BrainFarm.
          </li>
        </ul>
      </Body>
    </Container>
  )
}

export default TermsPage
