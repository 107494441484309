import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import TermsPage from "../components/TermsPage"

const Terms = () => {
  return (
    <>
      <SEO title="Terms" />
      <Navbar back bottomOffset={100} />
      <TermsPage />
      <Footer back />
    </>
  )
}

export default Terms
