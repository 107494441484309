import React from "react"
import styled from "styled-components"

const Container = styled.div`
  transform: rotate(-1deg);
  svg {
    enable-background: new 0 0 1200.7 201.1;
  }
`

const Heading = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 1200.7 201.1">
        <g>
          <path d="M36.2,27.7H0.2V3.2h125.4v24.6H90.1v71h-54V27.7z" />
          <path
            d="M188,40.7c0,0.1,17.3,0.1,51.8,0v19.5c-34.6,0.1-51.8,0.1-51.8,0v13.9h51.8v24.6H134.2V3.2h105.7v24.6
		c-34.6-0.3-51.8-0.3-51.8,0V40.7z"
          />
          <path
            d="M307.8,64.8h-2.3v33.9h-54V3.2h103c22.6,0,35.1,11.1,35.1,30.9c0,15.3-6.8,24.8-20.4,28.9L397,98.8h-65.7L307.8,64.8z
		 M334.6,33.5c0-7.7-6.1-12.4-13-12.4h-16v24.6h16C329.2,45.8,334.6,41.5,334.6,33.5z"
          />
          <path d="M474.7,98.8l-23.3-46.7v46.7h-49.8V3.2h63.9l20.4,41.1l20.4-41.1h63.8v95.6h-49.9V52.1L497,98.8H474.7z" />
          <path
            d="M617.7,66.1c-12.1,0-20.8-2.3-26.2-6.7c-5.9-4.9-8.8-13.4-8.8-25.4c0-16.6,7.3-27,22.1-30.9c7.2-1.9,21.7-3,43.5-3.2
		c7.4,0,14.1,0.1,20.1,0.4c14.3,0.5,23.8,1.4,28.6,2.8c5.4,1.5,9.5,4,11.9,7.6c2.6,3.8,4.3,9.6,4.8,17.5h-53.4
		c0-6.1-4.3-10.2-11.4-10.2c-7.9,0-11.9,2.9-11.9,8.6c0,2.5,1.4,4.5,4.2,6.2c2.4,1.4,4.8,2,7.3,2h34.3c12.1,0,20.8,2.3,26.2,6.8
		c5.9,4.9,8.8,13.2,8.8,25.2c0,9.7-2.1,17.3-6.6,22.8c-3.9,4.9-9.1,7.9-15.5,9.1c-5.5,1-11.2,1.6-17,1.9c-4.3,0.1-13.4,0.3-27.2,0.3
		c-29,0-46.3-0.8-52-2.1c-10.6-2.6-14.9-9.8-15.9-26.1h55.7c0,6.1,4.4,10.2,11.5,10.2c7.9,0,11.9-2.9,11.9-8.6
		c0-2.5-1.6-4.5-4.9-6.2c-2.6-1.4-5.2-2-7.8-2H617.7z"
          />
          <path d="M815.1,85.6l-4.5,13.1h-52.7l33.9-95.6h84.4L910,98.8h-52.6l-4.7-13.1H815.1z M845.3,64.4l-11.4-32.3l-11.4,32.3H845.3z" />
          <path d="M1000.4,39.7V3.2h51.2v95.6H999l-31.3-41v41h-51.2V3.2h53.7L1000.4,39.7z" />
          <path
            d="M1131.1,3.2c3.2,0,8.5-0.1,15.9-0.3c5.2-0.1,9.2,0,12.1,0.1c3.9,0.1,7.8,0.5,11.5,1.1c13.2,2.3,22.1,7.8,26.2,16.5
		c2.6,5.5,3.9,15.5,3.9,29.8c0,14.3-1.3,24.2-3.9,29.8c-4.2,8.7-13,14.3-26.2,16.5c-7.9,1.4-21.2,2-39.5,2H1064V3.2H1131.1z
		 M1144.6,71.4c1.3-3.2,1.9-10.3,1.9-21.6c0-8.2-0.8-15.3-2.3-20.9c-0.5-1.9-1.9-3.7-4-5.2c-2.5-1.6-5.4-2.5-8.6-2.5h-13.7v59.5
		h13.7C1137.8,80.7,1142.2,77.6,1144.6,71.4z"
          />
          <path
            d="M129.7,143.9H78.4c0-5.1-0.6-9.3-1.6-12.7c-0.6-2-1.9-3.7-3.9-5.2c-2.2-1.8-4.9-2.7-7.9-2.7c-6,0-10.1,2-12.1,6
		c-1.8,3.4-2.6,11.7-2.6,24.5c0,12.1,0.8,20.1,2.6,24c2.1,4.7,6.1,7.1,12.1,7.1s9.9-2.6,11.8-7.8c1.1-2.7,1.5-7.1,1.5-12.8h51.5
		c-0.4,10-2.2,17.5-5.5,22.5c-4.2,6.4-11.8,10.5-22.5,12.4c-7.7,1.3-20,2-36.9,2s-29.1-0.7-36.7-2c-12.4-2.2-20.6-7.5-24.5-16
		C1.2,177.5,0,167.9,0,154s1.2-23.5,3.7-29.1c3.9-8.5,12.1-13.8,24.5-16c7.7-1.3,19.9-2,36.7-2c17.1,0,29.3,0.7,36.9,2
		c10.7,1.9,18.3,6,22.5,12.5C127.5,126.4,129.4,133.9,129.7,143.9z"
          />
          <path
            d="M202.1,106.9c16.8,0,29.1,0.7,36.7,2c12.4,2.2,20.6,7.5,24.5,16c2.5,5.5,3.7,15.2,3.7,29.1s-1.2,23.5-3.7,29.1
		c-3.9,8.5-12.1,13.8-24.5,16c-7.7,1.3-19.9,2-36.7,2c-17,0-29.2-0.7-36.9-2c-12.4-2.2-20.6-7.5-24.5-16c-2.5-5.5-3.7-15.2-3.7-29.1
		s1.2-23.5,3.7-29.1c3.9-8.5,12.1-13.8,24.5-16C172.9,107.6,185.1,106.9,202.1,106.9z M202.1,184.8c6,0,10-2.4,12.1-7.1
		c1.8-3.9,2.6-11.9,2.6-24c0-12.8-0.8-21.1-2.6-24.5c-2-4-6.1-6-12.1-6c-6,0-10,2-12.1,6c-1.6,3.2-2.5,11.3-2.5,24.5
		c0,12.4,0.8,20.4,2.5,24C192,182.5,196.1,184.8,202.1,184.8z"
          />
          <path d="M355.3,144v-34.1h47.8v89.2H354l-29.2-38.3v38.3H277v-89.2h50.2L355.3,144z" />
          <path
            d="M477.2,109.8c2.9,0,7.9-0.1,14.8-0.2c4.8-0.1,8.6,0,11.3,0.1c3.7,0.1,7.3,0.5,10.7,1.1c12.4,2.1,20.6,7.3,24.5,15.4
		c2.5,5.2,3.7,14.5,3.7,27.8c0,13.3-1.2,22.6-3.7,27.8c-3.9,8.1-12.1,13.3-24.5,15.4c-7.4,1.3-19.8,1.9-36.9,1.9h-62.6v-89.2H477.2z
		 M489.8,173.5c1.2-2.9,1.8-9.7,1.8-20.1c0-7.7-0.7-14.2-2.1-19.5c-0.5-1.8-1.8-3.4-3.8-4.8c-2.4-1.5-5.1-2.4-8-2.4h-12.8v55.6h12.8
		C483.5,182.3,487.6,179.3,489.8,173.5z"
          />
          <path d="M552.3,199.1v-89.2h50.3v89.2H552.3z" />
          <path d="M646.1,132.8h-33.6v-23h117v23h-33.1v66.3h-50.4V132.8z" />
          <path d="M737.1,199.1v-89.2h50.3v89.2H737.1z" />
          <path
            d="M862.6,106.9c16.8,0,29.1,0.7,36.7,2c12.4,2.2,20.6,7.5,24.5,16c2.5,5.5,3.7,15.2,3.7,29.1s-1.2,23.5-3.7,29.1
		c-3.9,8.5-12.1,13.8-24.5,16c-7.7,1.3-19.9,2-36.7,2c-17,0-29.2-0.7-36.9-2c-12.4-2.2-20.6-7.5-24.5-16c-2.5-5.5-3.7-15.2-3.7-29.1
		s1.2-23.5,3.7-29.1c3.9-8.5,12.1-13.8,24.5-16C833.4,107.6,845.7,106.9,862.6,106.9z M862.6,184.8c6,0,10-2.4,12.1-7.1
		c1.8-3.9,2.6-11.9,2.6-24c0-12.8-0.8-21.1-2.6-24.5c-2-4-6.1-6-12.1-6c-6,0-10,2-12.1,6c-1.6,3.2-2.5,11.3-2.5,24.5
		c0,12.4,0.8,20.4,2.5,24C852.6,182.5,856.6,184.8,862.6,184.8z"
          />
          <path d="M1015.8,144v-34.1h47.8v89.2h-49.1l-29.2-38.3v38.3h-47.8v-89.2h50.2L1015.8,144z" />
          <path
            d="M1107.2,168.6c-11.3,0-19.4-2.1-24.5-6.2c-5.5-4.6-8.2-12.5-8.2-23.7c0-15.5,6.8-25.2,20.6-28.8c6.7-1.8,20.3-2.8,40.6-2.9
		c6.9,0,13.2,0.1,18.7,0.4c13.3,0.5,22.3,1.3,26.7,2.6c5.1,1.4,8.8,3.8,11.1,7.1c2.5,3.5,4,8.9,4.5,16.4h-49.8c0-5.7-4-9.5-10.6-9.5
		c-7.4,0-11.1,2.7-11.1,8c0,2.4,1.3,4.2,3.9,5.8c2.2,1.3,4.5,1.9,6.8,1.9h32c11.3,0,19.4,2.1,24.5,6.4c5.5,4.6,8.2,12.4,8.2,23.5
		c0,9.1-2,16.1-6.1,21.3c-3.6,4.6-8.5,7.4-14.5,8.5c-5.2,0.9-10.5,1.5-15.9,1.8c-4,0.1-12.5,0.2-25.4,0.2c-27.1,0-43.2-0.7-48.5-2
		c-9.9-2.5-13.9-9.2-14.8-24.4h52c0,5.7,4.1,9.5,10.7,9.5c7.4,0,11.1-2.7,11.1-8c0-2.4-1.5-4.2-4.6-5.8c-2.5-1.3-4.8-1.9-7.3-1.9
		H1107.2z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Heading
